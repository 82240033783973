import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { gql, useQuery } from '@apollo/client';

type AttributeOption = {
  label: string;
  value: string;
};

const buildAttributeOptions = (attribute): AttributeOption[] => {
  const { labels, values } = attribute.data ?? { labels: [], values: [] };
  return values.map((value, index) => ({ value, label: labels[index] }));
};

const ENUMERABLE_MUTI_VALUES_QUERY = gql`
  query GetQAEnumerableMutiOptions($qualificationAttributeId: Int!) {
    qualificationAttributeOptions(id: $qualificationAttributeId) {
      label
      value
    }
  }
`;

const useEnumerableMutiOptions = (qualificationAttributeId: number) => {
  const { data, loading: isLoading } = useQuery<AttributeOption[]>(ENUMERABLE_MUTI_VALUES_QUERY, {
    skip: !qualificationAttributeId,
    variables: { qualificationAttributeId },
  });

  return {
    options: data?.qualificationAttributeOptions ?? [],
    isLoading: isLoading || !data?.qualificationAttributeOptions,
  };
};

export const EnumerableMultiInput = ({ value = [], onChange, attribute }) => {
  const { options, isLoading } = useEnumerableMutiOptions(attribute.id);
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const newValue = typeof fieldValue === 'string' ? fieldValue.split(',') : fieldValue;
    onChange(newValue);
  };
  return (
    <FormControl fullWidth disabled={isLoading}>
      <Select value={Array.isArray(value) ? value : []} multiple onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const EnumerableInput = ({ value = [], onChange, attribute }) => {
  const options = buildAttributeOptions(attribute);
  const handleChange = (event) => {
    const fieldValue = event.target.value;
    onChange(fieldValue);
  };
  return (
    <FormControl fullWidth>
      <Select value={value} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
